import ArrayUtilities from '@/utilities/array';
import PlotType from './plot-type';
import { ContinuousPlotColorPoint, DiscretePlotColorPoint } from './color-data-point.ts';
import { CONTINUOUS_PLOT_CHUNK_SIZE, DISCRETE_PLOT_CHUNK_SIZE } from './color-scale-constants';

class ColorScaleData {
  get colorDataPoints() {
    switch (this.plotType) {
    case PlotType.CONTINUOUS: return this.continuousPlotColorPoints;
    case PlotType.DISCRETE: return this.discretePlotColorPoints;
    default: return [];
    }
  }

  get continuousPlotColorPoints() {
    return ArrayUtilities
      .chunk(this.rgbPoints, CONTINUOUS_PLOT_CHUNK_SIZE)
      .map(colourChunk => new ContinuousPlotColorPoint(colourChunk));
  }

  get discretePlotColorPoints() {
    return ArrayUtilities
      .chunk(this.rgbPoints, DISCRETE_PLOT_CHUNK_SIZE)
      .map(colourChunk => new DiscretePlotColorPoint(colourChunk));
  }

  get xValues() {
    switch (this.plotType) {
    case PlotType.CONTINUOUS: return this.colorDataPoints.map(point => point.x);
    case PlotType.DISCRETE: return null;
    default: return null;
    }
  }

  get xMin() {
    return Math.min(...this.xValues);
  }

  get xMax() {
    return Math.max(...this.xValues);
  }

  get continuousPlotRgbaString() {
    return this.colorDataPoints
      .map(point => `rgba(${point.red}, ${point.green}, ${point.blue}, 1) ${this.normalizedPercent(point.x, this.xMin, this.xMax)}%`)
      .join();
  }

  get discretePlotRgbaString() {
    return this.colorDataPoints
      .map((point, index) => `rgba(${point.red}, ${point.green}, ${point.blue}, 1) ${this.percentForIndex(index)}`)
      .join();
  }

  get rgbaString() {
    switch (this.plotType) {
    case PlotType.CONTINUOUS: return this.continuousPlotRgbaString;
    case PlotType.DISCRETE: return this.discretePlotRgbaString;
    default: return null;
    }
  }

  get linearGradientString() {
    return this.rgbaString ? `linear-gradient(90deg,${this.rgbaString})` : null;
  }

  get continuousPlotXValues() {
    return ArrayUtilities
      .chunk(this.points, CONTINUOUS_PLOT_CHUNK_SIZE)
      .map(pointChunk => new ContinuousPlotColorPoint(pointChunk));
  }

  constructor(plotType, rgbPoints, points) {
    this.plotType = plotType;
    this.rgbPoints = rgbPoints;
    this.points = points;
  }

  normalizedPercent(value, min, max) {
    return ((value - min) / (max - min)) * 100;
  }

  percentForIndex(index) {
    return `${(index * 100) / this.colorDataPoints.length}% ${((index + 1) * 100) / this.colorDataPoints.length}%`;
  }

  cssAnnotationPercentages(annotations) {
    switch (this.plotType) {
    case PlotType.CONTINUOUS: return this.continuousPlotAnnotationPercentages(annotations.filter(annotation => typeof annotation === 'number'));
    case PlotType.DISCRETE: return this.discretePlotAnnotationPercentages(annotations.filter(annotation => typeof annotation === 'string'));
    }
  }

  continuousPlotAnnotationPercentages(annotations) {
    return annotations.map(annotation => this.normalizedPercent(annotation, this.continuousPlotXValues[0].x, this.continuousPlotXValues[this.continuousPlotXValues.length - 1].x));
  }

  discretePlotAnnotationPercentages(annotations) {
    const offset = 100 / (annotations.length * 2);
    const position = 100 / annotations.length;
    return annotations.map((value, index) => (index * position) + offset);
  }
}

export default ColorScaleData;