interface ColorPoint {
  readonly red: number;
  readonly green: number;
  readonly blue: number;
}

class ContinuousPlotColorPoint implements ColorPoint {
  x: number;
  r: number;
  g: number;
  b: number;

  get red(): number {
    return this.r * 255;
  }

  get green(): number {
    return this.g * 255;
  }

  get blue(): number {
    return this.b * 255;
  }

  constructor(colourChunk: number[]) {
    this.x = colourChunk[0];
    this.r = colourChunk[1];
    this.g = colourChunk[2];
    this.b = colourChunk[3];
  }
}

class DiscretePlotColorPoint implements ColorPoint {
  r: number;
  g: number;
  b: number;

  get red() {
    return this.r * 255;
  }

  get green() {
    return this.g * 255;
  }

  get blue() {
    return this.b * 255;
  }

  constructor(colourChunk: number[]) {
    this.r = colourChunk[0];
    this.g = colourChunk[1];
    this.b = colourChunk[2];
  }
}

export {
  ContinuousPlotColorPoint,
  DiscretePlotColorPoint
};