export default {
  groupBy(array, key) {
    return array.reduce((accumulator, value) => {
      accumulator[value[key]] = accumulator[value[key]] || [];
      accumulator[value[key]].push(value);
      return accumulator;
    }, {});
  },
  chunk(array, size) {
    if (!array || !size) {
      return [];
    }
    return Array.from({ length: Math.ceil(array.length / size) }, (unusedValue, index) => array.slice(index * size, index * size + size));
  }
};